import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'; //导入组件
import 'element-ui/lib/theme-chalk/index.css';  //导入组件样式
import '@/assets/font/font.css';
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

import { TreeTransfer } from 'element-ui';

Vue.use(ElementUI);

Vue.component('el-tree-transfer', TreeTransfer);

Vue.config.productionTip = false
Vue.use(ElementUI);//全局使用

Vue.prototype.$bus = new Vue();//事件总线


import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

import http from './utils/request'
Vue.prototype.$axios = http

new Vue({
  render: h => h(App),
  router:router,
}).$mount('#app')
