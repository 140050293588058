import axios from "axios";

// import router from "../router";


const http = axios.create({
  baseURL: '/api',
  // baseURL:'/jx230805zhangkaida',
  timeout: 50000,//ms
  // headers: {
  //   'Authorization': `${sessionStorage.getItem('token')}`,
  // }
});


http.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';

// 拦截器
    // 请求拦截：前置拦截  请求头：可以设置一些固定的值
    http.interceptors.request.use(config => {
      if(localStorage.getItem('token')){
          config.headers.token=localStorage.getItem('token');
      }
    return config;
  });
  
   // 应答拦截：后置拦截，做一些统一的错误处理
  http.interceptors.response.use(res=>{
      // console.log(res.data.code+"响应拦截")
      if(res.data.code==666){
          alert('登录过期，请重新登陆')
          window.location.href = "/";
          localStorage.removeItem('token')
      }
      return res.data;
  })

export default http;