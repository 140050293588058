//1、导入Vue和Vue-router
import Vue from "vue";
import VueRouter from "vue-router";

//2、vue模块使用vuerouter
Vue.use(VueRouter)

//3、路由/路由表
var routes=[
    // {name:'main',path:"/",component:()=>import('@/views/MainView.vue')},
    {name:'log',path:"/",component:()=>import('@/views/AdminLogin.vue')},
    {name:'log',path:"/log",component:()=>import('@/views/AdminLogin.vue')},
    {name:'main',path:"/main",component:()=>import('@/views/MainView.vue'),meta:{authRequired:true},
    
    children:[
        {name:'courierstate',path:"/courierstate",component:()=>import('@/views/CourierStateView.vue')},
        {name:'couriertime',path:"/time",component:()=>import('@/views/CourierTime.vue')},
    {name:'order',path:"/order",component:()=>import('@/views/OrderQuantityView.vue')},
    {name:'rule',path:"/rule",component:()=>import('@/views/rulesView.vue')},
    {name:'coupon',path:"/coupon",component:()=>import('@/views/CouponManergeView.vue')},
    {name:'admin',path:"/admin",component:()=>import('@/views/AdminListView.vue')},
    {name:'user',path:"/user",component:()=>import('@/views/UserListView.vue')},
    {name:'menu',path:"/menu",component:()=>import('@/views/MenuView.vue')},
    {name:'orderdatails',path:"/orderdatails",component:()=>import('@/views/OrderManageView.vue')},
    {name:'courier',path:"/courier",component:()=>import('@/views/CourierView.vue')},
    ]}

]

//4、路由器
var router=new VueRouter({
    routes:routes,
    mode:'hash'
})

router.beforeEach((to,from,next)=>{
	if(to.path==='/'|| to.path==='/log'){
		next()
	}else{
		let token = localStorage.getItem('token');
		console.log("token为："+token);
		if(token==null||token==''){
			alert("没有权限，请登录");
			return next('/');
		}else{
			next();
		}
	}
})

//5、导出
export default router